class DavronAttachments extends frappe.ui.form.Attachments {
	refresh() {
		var me = this;

		if (this.frm.doc.__islocal) {
			this.parent.toggle(false);
			return;
		}
		this.parent.toggle(true);
		this.parent.find(".attachment-row").remove();

		var max_reached = this.max_reached();
		this.add_attachment_wrapper.toggle(!max_reached);

		// add attachment objects
		var attachments = this.get_attachments();
		if (attachments.length) {
			let exists = {};
			let unique_attachments = attachments.filter((attachment) => {
				return Object.prototype.hasOwnProperty.call(exists, attachment.name)
					? false
					: (exists[attachment.name] = true);
			});
			unique_attachments.forEach((attachment) => {
				me.add_attachment(attachment);
			});
		} else {
			this.attachments_label.removeClass("has-attachments");
		}
	}
}

$(document).ready(() => {
	frappe.ui.form.Attachments = DavronAttachments;
})