import DataTable from "frappe-datatable";

frappe.provide('davrontranslations')
frappe.provide('davrontranslations.updates');

frappe.utils.make_event_emitter(davrontranslations.updates);


davrontranslations.supplier_comparator = class SupplierComparator {
	constructor(opts) {
		Object.assign(this, opts)
		this.items = this.doc.items.map(r => { return r.item_code })
		this.selected_tags = [];
		this.selected_item = this.items[0];
		this.from_date, this.to_date = null, null;
		this.options = []

		this.make_dialog()
	}

	async make_dialog() {
		const me = this;

		await this.get_options()

		this.dialog = new frappe.ui.Dialog({
			title: 'Supplier Comparator',
			size:"extra-large",
			fields: [
				{
					fieldtype: 'Select',
					fieldname: 'item_selector',
					label: 'Please select an item',
					options: me.items,
					default: me.items[0],
					onchange: async function() {
						me.selected_item = me.dialog.fields_dict.item_selector.value;
						await me.get_options()
					}
				},
				{
					fieldtype: 'Section Break',
					fieldname: 'dates_section'
				},
				{
					fieldtype: 'Date',
					fieldname: 'start_date',
					label: 'Start Date',
					default: frappe.datetime.nowdate(),
					onchange: function() {
						me.from_date = me.dialog.fields_dict.start_date.value;
						me.get_corresponding_suppliers()
					}
				},
				{
					fieldtype: 'Column Break',
					fieldname: 'dates_columns'
				},
				{
					fieldtype: 'Date',
					fieldname: 'end_date',
					label: 'End Date',
					default: frappe.datetime.year_end(),
					onchange: function() {
						me.to_date = me.dialog.fields_dict.end_date.value;
						me.get_corresponding_suppliers()
					}
				},
				{
					fieldtype: 'Section Break',
					fieldname: 'comparator_section'
				},
				{
					fieldtype: "MultiSelectPills",
					fieldname: "tags",
					label: __("Tags"),
					get_data: function (txt) {
						return me.options
					},
					onchange: function() {
						const values = me.dialog.get_values()
						me.selected_tags = values.tags || []
						me.get_item_attributes()
					}
				},
				{
					fieldtype: 'HTML',
					fieldname: 'comparator_area'
				}
			]
		});

		this.wrapper = this.dialog.fields_dict.comparator_area.$wrapper[0];
		this.dialog.show();
		this.dialog.$wrapper.on('hidden.bs.modal', function() {
			$(this).data('bs.modal', null);
			$(this).remove();
		});
	}

	get_item_attributes() {
		frappe.xcall('davrontranslations.utils.supplier_comparator.get_item_attributes', {
			item: this.selected_item
		})
		.then(r => {
			if (r && r.tag) {
				if (!this.selected_tags.includes(r.tag)) {
					this.selected_tags.push(r.tag)
				}
			}
			this.get_corresponding_suppliers()
		})
	}

	get_corresponding_suppliers() {
		if (this.selected_item) {
			frappe.xcall('davrontranslations.utils.supplier_comparator.get_corresponding_suppliers_details', {
				item: this.selected_item,
				tags: this.selected_tags,
				from_date: this.from_date,
				to_date: this.to_date
			})
			.then(r => {
				this.render_datatable(r)
			})
		}
	}

	async get_options() {
		this.options = await frappe.xcall('davrontranslations.utils.supplier_comparator.get_supplier_tags')
	}

	render_datatable(data) {
		if (this.datatable) {
			this.datatable.destroy();
		}

		this.datatable = new DataTable(this.wrapper, {
			data: data,
			columns: this.get_columns(),
			layout: "fluid",
			cellHeight: 35,
			language: frappe.boot.lang,
			translations: frappe.utils.datatable.get_translations(),
			serialNoColumn: false,
			checkboxColumn: false,
			noDataMessage: __("No Data"),
			disableReorderColumn: true,
		});
	}

	get_columns() {
		return [
			{
				"id": "name",
				"name": __("Name")
			},
			{
				"id": "tags",
				"name": __("Tags")
			},
			{
				"id": "item_price",
				"name": __("Item Price")
			},
			{
				"id": "score",
				"name": __("Score")
			},
			{
				"id": "availability_status",
				"name": __("Availability Status")
			},
			{
				"id": "unavailabilities",
				"name": __("Unavailabilities")
			}
		]
	}
}